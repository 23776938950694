import React from "react";
import { Edit, SimpleForm, TextInput, useNotify } from "react-admin";
import { errorHandlingFactory } from "../../utils/errors";

export default function CenterEdit() {
  const notify = useNotify();
  const onError = errorHandlingFactory(notify);

  return (
    <Edit mutationOptions={{ onError }}>
      <SimpleForm>
        <TextInput disabled label="Id" source="id" />
        <TextInput source="name" />
        <TextInput source="number" />
        <TextInput source="internalNotes" multiline fullWidth />
        <TextInput source="keypadCode" />
      </SimpleForm>
    </Edit>
  );
}
